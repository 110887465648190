import { produce, useOpenDashServices, useUserService } from "@opendash/core";
import { Icon } from "@opendash/icons";
import { $parse, ParseFilterState, ParseSetView } from "@opendash/plugin-parse";
import {
  Button,
  DatePicker,
  Divider,
  InputNumber,
  InputNumberProps,
  notification,
  Select,
  Tag,
  Typography,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import Parse from "parse";
import React, { useEffect } from "react";
import { useAkkordMetaData } from "../hooks/useAkkordMetaData";
import { Kuenne_QATest, OD3_Source } from "../types";
import { _User } from "../types/_User";
import {
  Kuenne_AkkordEintraege,
  Kuenne_AkkordEintraegeAttributes,
} from "../types/Kuenne_AkkordEintraege";
import AkkordResultDrawer from "./AkkordResultDrawer";

type AkkordComponentProps = {};
const classname = "Kuenne_AkkordEintraege";

const AkkordComponent = (props: AkkordComponentProps) => {
  const styleOfForm: React.CSSProperties = { minWidth: 100, flexGrow: 1 };
  const { SourceService } = useOpenDashServices();
  const [date, setDate] = React.useState<Dayjs | undefined>(dayjs());
  const [worker, setWorker] = React.useState<Parse.User<_User> | undefined>(
    undefined
  );
  const [resultopen, setResultopen] = React.useState<boolean>(false);

  const [newEntry, setNewEntry] = React.useState<
    Partial<Kuenne_AkkordEintraegeAttributes>
  >({ datum: dayjs().toDate() });

  const { users, machines, orders: allOrders } = useAkkordMetaData(newEntry);
  const [orders, setOrders] = React.useState(allOrders);
  const allMachines = SourceService.getAll().filter((cSource) => !!cSource.tag);
  const view = $parse.ui.defaultViewMap[classname];
  const [reloadTable, setReloadTable] = React.useState<number>(0);

  useEffect(() => {
    if (allOrders.length > 0) {
      setOrders(allOrders);
    }
  }, [allOrders]);

  const state = React.useMemo(() => {
    const newState = new ParseFilterState(classname, view, false);
    newState.setPageSize(1000);
    newState.setCustomQuery((query) => {
      query.limit(1000);
      query.descending("datum");

      if (date) {
        query.greaterThanOrEqualTo("datum", date.startOf("month").toDate());
        query.lessThanOrEqualTo("datum", date.endOf("month").toDate());
      }
      if (worker) {
        query.equalTo("werker", worker);
      }
    });

    return newState;
  }, [date, worker, reloadTable]);

  const commonNumberInputsProps = {
    decimalSeparator: ",",
    min: 0,
  };

  const fetchOrdersById = React.useCallback((id: string) => {
    const query = new Parse.Query(Kuenne_QATest);
    query.equalTo("AUFNR", id);
    query
      .find()
      .then((res) => {
        setOrders(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const saveEntry = () => {
    const newEntryInstance = new Kuenne_AkkordEintraege(newEntry);
    newEntryInstance.setACL(
      new Parse.ACL({ "role:Akkord-Admin": { read: true, write: true } })
    );
    newEntryInstance.set("monat", newEntry.datum!.getMonth() + 1);
    newEntryInstance.set("jahr", newEntry.datum!.getFullYear());
    newEntryInstance
      .save()
      .then((res) => {
        setNewEntry({
          ...newEntry,
          //@ts-ignore
          werker: newEntry.werker || worker,
          datum: date?.toDate(),
          zaehlerStart: newEntry.zaehlerEnde,
          zaehlerEnde: undefined,
        });
        notification.success({
          message: "Eintrag erfolgreich gespeichert",
          description: `${res.get("werker")?.getUsername()} - ${
            newEntry.datum!.getMonth() + 1
          }/${newEntry.datum!.getFullYear()} - ${
            newEntry.auftrag?.AUFNR || "Ohne Auftrag"
          }`,
        });
        setReloadTable((draft) => draft + 1);
      })
      .catch((error) => {
        notification.error({
          message: "Fehler beim Speichern",
          description: error.message,
        });
      });
  };

  React.useEffect(() => {
    if (newEntry.auftrag?.AUFNR) {
      setNewEntry(
        produce((draft) => {
          draft.maschine = machines[0];

          if (newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM) {
            draft.durchmesser = newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM;
          }
        })
      );
    }
  }, [newEntry.auftrag?.AUFNR]);

  // React.useEffect(() => {
  //   if (newEntry.auftrag?.AUFNR && newEntry.maschine?.id) {
  //     setNewEntry(
  //       produce((draft) => {
  //         draft.maschine = machines[0];
  //         if (newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM) {
  //           draft.durchmesser = newEntry.auftrag?.PRUEFERGEBNIS?.DURCHM;
  //         }
  //       })
  //     );
  //   }
  // }, [newEntry.maschine?.id]);

  React.useEffect(() => {
    if (!newEntry.datum && date) {
      setNewEntry(
        produce((draft) => {
          draft.datum = date?.toDate();
        })
      );
    }
    if (!newEntry.werker && worker) {
      setNewEntry(
        produce((draft) => {
          //@ts-ignore
          draft.werker = worker;
        })
      );
    }
  }, [date, worker]);
  const newEntryCompoent = (
    <>
      <Divider orientation="left">
        <Typography.Text type="secondary">Neuer Eintrag</Typography.Text>{" "}
      </Divider>
      <div
        style={{
          width: "100%",
          minWidth: "1200",
          overflowX: "auto",

          display: "flex",
          flexDirection: "row",
          gap: "2px",
        }}
      >
        <Select
          style={{ ...styleOfForm, flexGrow: 1 }}
          showSearch={true}
          placeholder={"Werker"}
          value={newEntry.werker?.id || null}
          filterOption={(input, option) => {
            const res =
              option?.label
                ?.toLocaleLowerCase()
                .indexOf(input.toLocaleLowerCase()) !== -1;
            return res;
          }}
          options={users.map((user) => ({
            label: user.getUsername(),
            value: user.id,
          }))}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                //@ts-ignore
                draft.werker = users.find((user) => user.id === value);
              })
            );
          }}
        ></Select>
        <DatePicker
          style={{ ...styleOfForm, width: 75, flexGrow: 0 }}
          picker="month"
          value={newEntry.datum ? dayjs(newEntry.datum) : null}
          placeholder="Monat"
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.datum = value ? value.toDate() : undefined;
                delete draft.auftrag;
              })
            );
          }}
        />
        <Select
          placeholder={"Auftrag"}
          style={{ ...styleOfForm, flexGrow: 1.5 }}
          value={newEntry.auftrag?.id || null}
          allowClear={true}
          onClear={() => {
            setOrders(allOrders);
          }}
          onSearch={(value) => {
            if (value.length >= 7) {
              fetchOrdersById(value);
            } else {
              setOrders(allOrders);
            }
          }}
          filterOption={(input, option) => {
            const res =
              option?.label
                ?.toLocaleLowerCase()
                .indexOf(input.toLocaleLowerCase()) !== -1;
            return res;
          }}
          showSearch={true}
          options={
            newEntry.datum
              ? orders
                  .filter((order) => {
                    const start = dayjs(newEntry.datum?.getTime())
                      .startOf("month")
                      .subtract(1, "month")
                      .valueOf();
                    const end = dayjs(newEntry.datum?.getTime())
                      .endOf("month")
                      .valueOf();
                    return (
                      order.get("AENDATETIME")?.getTime() ||
                      (0 > start && order.get("AENDATETIME")?.getTime()) ||
                      0 < end
                    );
                  })
                  .map((order) => ({
                    label: `${order.get("AUFNR")} | ${order.get(
                      "POSNR"
                    )} - ${order.get("NAME")}`,
                    value: order.id,
                  }))
                  .filter(
                    (order, index, cOrders) =>
                      index ===
                      cOrders.findIndex(
                        (cOrder) => cOrder.label === order.label
                      )
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))
              : orders
                  .map((order) => ({
                    label: `${order.get("AUFNR")} | ${order.get(
                      "POSNR"
                    )} - ${order.get("NAME")}`,
                    value: order.id,
                  }))
                  .filter(
                    (order, index, cOrders) =>
                      index ===
                      cOrders.findIndex(
                        (cOrder) => cOrder.label === order.label
                      )
                  )
                  .sort((a, b) => a.label.localeCompare(b.label))
          }
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.auftrag = orders.find((order) => order.id === value);
              })
            );
          }}
        />
        <Select
          placeholder={"Maschine"}
          style={{ ...styleOfForm, flexGrow: 1 }}
          value={newEntry.maschine?.id || null}
          filterOption={(input, option) => {
            const res =
              option?.label
                ?.toLocaleLowerCase()
                .indexOf(input.toLocaleLowerCase()) !== -1;
            return res;
          }}
          showSearch={true}
          options={allMachines.map((machine) => ({
            label: machine.name,
            value: machine.id,
          }))}
          dropdownRender={(menu) => {
            return (
              <>
                {newEntry.auftrag && (
                  <>
                    <Typography.Text type="secondary">
                      Aus Auftrag
                    </Typography.Text>

                    <div style={{ padding: "2px" }}>
                      {machines.map((cMachine) => (
                        <Tag
                          onClick={() => {
                            setNewEntry(
                              produce((draft) => {
                                draft.maschine = machines.find(
                                  (m) => m.id === cMachine.id
                                );
                              })
                            );
                          }}
                        >
                          {cMachine.name}
                        </Tag>
                      ))}
                    </div>

                    <Typography.Text type="secondary">
                      Sonstige Maschinen
                    </Typography.Text>
                  </>
                )}

                {menu}
              </>
            );
          }}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                const source = allMachines.find((m) => m.id === value);
                if (source) {
                  draft.maschine = new OD3_Source({ id: source?.id });
                }
                draft.zaehlerStart = 0;
              })
            );
          }}
        ></Select>
        <InputNumber
          {...commonNumberInputsProps}
          value={newEntry.durchmesser}
          placeholder="Durchmesser"
          style={styleOfForm}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.durchmesser = value || undefined;
              })
            );
          }}
        ></InputNumber>
        <InputNumber
          {...commonNumberInputsProps}
          placeholder="Geschwindigkeit"
          value={newEntry.geschwindigkeit}
          style={styleOfForm}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.geschwindigkeit = value || undefined;
              })
            );
          }}
        ></InputNumber>
        <InputNumber
          {...commonNumberInputsProps}
          placeholder="Gewicht"
          style={styleOfForm}
          value={newEntry.gewicht}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.gewicht = value || undefined;
              })
            );
          }}
        ></InputNumber>
        <InputNumber
          {...commonNumberInputsProps}
          placeholder="Zähler Start"
          value={newEntry.zaehlerStart}
          style={styleOfForm}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.zaehlerStart = value ?? undefined;
              })
            );
          }}
        ></InputNumber>
        <InputNumber
          {...commonNumberInputsProps}
          placeholder="Zähler Ende"
          value={newEntry.zaehlerEnde}
          style={styleOfForm}
          onChange={(value) => {
            setNewEntry(
              produce((draft) => {
                draft.zaehlerEnde = value ?? 0;
              })
            );
          }}
        ></InputNumber>
        <Button
          type="primary"
          onClick={saveEntry}
          style={{ width: "60px", minWidth: "60px", marginLeft: 16 }}
          icon={<Icon icon="fa:save"></Icon>}
        ></Button>
      </div>
      <Divider orientation="left" style={{ marginBottom: -50 }}>
        <Typography.Text type="secondary">
          {`Vorhandene Einträge ${date ? "im Monat " : ""} ${
            worker ? "nach Werker" : ""
          }`}
        </Typography.Text>
      </Divider>
    </>
  );

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        background: "#fff",
        margin: 16,
        paddingBottom: 16,
      }}
    >
      <ParseSetView
        className={classname}
        hideCreate={true}
        state={state}
        view={view}
        headerExtra={[
          <DatePicker
            picker="month"
            value={date}
            style={{ width: "100%" }}
            onChange={(value) => {
              setDate(value);
            }}
          />,
          <Select
            style={{ width: "100%", marginTop: "8px" }}
            showSearch={true}
            allowClear={true}
            placeholder={"Werker"}
            value={worker?.id || null}
            filterOption={(input, option) => {
              const res =
                option?.label
                  ?.toLocaleLowerCase()
                  .indexOf(input.toLocaleLowerCase()) !== -1;
              return res;
            }}
            options={users.map((user) => ({
              label: user.getUsername(),
              value: user.id,
            }))}
            onChange={(value) => {
              //@ts-ignore
              setWorker(users.find((user) => user.id === value) || undefined);
            }}
          ></Select>,
          newEntryCompoent,
        ]}
        onRow={(row: Parse.Object, index: number) => {
          return {
            onClick: (event: React.MouseEvent) => {}, // click row
            onDoubleClick: (event: React.MouseEvent) => {}, // double click row
            onContextMenu: (event: React.MouseEvent) => {}, // right button click row
            onMouseEnter: (event: React.MouseEvent) => {}, // mouse enter row
            onMouseLeave: (event: React.MouseEvent) => {}, // mouse leave row
          };
        }}
      />
      <div style={{ width: "100%", padding: "0px 8px" }}>
        <Button
          type="primary"
          style={{ width: "100%" }}
          onClick={() => {
            setResultopen(true);
          }}
        >
          Auswertung anzeigen
        </Button>
      </div>

      {resultopen && (
        <AkkordResultDrawer
          open={resultopen}
          placement="bottom"
          height={"100%"}
          closable={true}
          onClose={() => {
            setResultopen(false);
          }}
          worker={worker}
          month={date?.toDate() || undefined}
        />
      )}
    </div>
  );
};

export default AkkordComponent;
